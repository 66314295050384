import React from 'react'
import { motion } from "framer-motion";

const Privacy = () => {
    return (
        <>
            <div className='privacy'>
                <div className="privacy-div">
                    <h1>Privacy Policy</h1>
                    <p>Thank you for choosing to be part of our community at Shreesoftic (“Company”, “we”, “us”, “our”). We are committed to protecting your personal information and your right to privacy.</p>
                </div>
            </div>

            <div className="privacy-section">
                <div className="privacy-flex">
                    <div className="privacy-details">
                        <p> </p>

                        <p>If you have any questions or concerns about this privacy notice or our practices with regards to your personal information,

                            please contact us at <a href="mailto: contact@shreesoftic.com" aria-hidden='contact email'>contact@shreesoftic.com</a></p>

                        <p>When you visit our website <a href="www.shreesoftic.com" aria-hidden='visit website link'>www.shreesoftic.com</a> (the “Website”), and more generally, use any of our services (the “Services”, which include the Website), we appreciate that you are trusting us with your personal information.</p>

                        <p>We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it.</p>


                        <p>We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue the use of our services immediately.</p>

                        <p>This privacy notice applies to all information collected through our Services (which, as described above, includes our Website), as well as, any related services, sales, marketing, or events. Please read this privacy notice carefully to help you understand what we do with the information we collect.</p>

                        <h3 className='privacy-title'>1. What information do we collect?</h3>

                        <p>The personal information you disclose to us. In Short: We collect personal information that you provide to us. We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services when you participate in activities on the Website or otherwise when you contact us.</p>


                        <p>The personal information that we collect depends on the context of your interactions with us and the Website, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>

                        <h5>Personal Information Provided by You.</h5>
                        <p>We collect names; phone numbers; email addresses; mailing addresses; and other similar information.</p>

                        <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>


                    </div>
                    <motion.div
                        initial={{ x: 100, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        className="privacy-description">
                        <img src='/Images/privacyimg.png' aria-hidden="true" />
                    </motion.div>
                </div>

            </div >


            <div className="privacy-security">
                <div className="privacy-que">
                    <div className="privacy-details-que">

                        <h5>Information automatically collected</h5>

                        <p>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our website. We automatically collect certain information when you visit, use or navigate the website. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser, and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Website and other technical information. This information is primarily needed to maintain the security and operation of our Website, and for our internal analytics and reporting purposes.</p>

                        <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
                        <h5>The information we collect includes:</h5>
                        <h5>Log and Usage Data</h5>

                        <p>Log and usage data are service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Website and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings, and information about your activity on the Website (such as the date/time stamps associated with your usage, pages, and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called ‘crash dumps’) and hardware settings).</p>

                        <h5>Device Data</h5>

                        <p>We collect device data such as information about your computer, phone, tablet, or other devices you use to access the Website. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system, and system configuration information.</p>

                        <h5>Location Data</h5>
                        <p>We collect location data such as information about your device’s location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Website. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt-out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device.</p>

                        <p>Note: however, if you choose to opt-out, you may not be able to use certain aspects of the Services.</p>

                        <h3 className='privacy-title'>2. How do we use your information?</h3>
                        <p>We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent. We use personal information collected via our Website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</p>


                        <h5>We use the information we collect or receive</h5>
                        <p>To send administrative information to you. We may use your personal information to send you a product, service, and new feature information, and/or information about changes to our terms, conditions, and policies.</p>

                        <p>To protect our Services. We may use your information as part of our efforts to keep our Website safe and secure (for example, for fraud monitoring and prevention).</p>



                        <p>To enforce our terms, conditions, and policies for business purposes, comply with legal and regulatory requirements, or connect with our contract.</p>

                        <p>To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</p>

                        <p>To send you marketing and promotional communications. We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes if this is in accordance with your marketing preferences. For example, when expressing an interest in obtaining information about us or our Website, subscribing to marketing, or otherwise contacting us, we will collect personal information from you.</p>

                        <p>You can opt out of our marketing emails at any time (see the “WHAT ARE YOUR PRIVACY RIGHTS?” below). ​</p>

                        <p>Deliver targeted advertising to you. We may use your information to develop and display personalized content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.</p>


                        <h3 className='privacy-title'>3. Will your information be shared with anyone?</h3>

                        <p>We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>

                        <p>We may process or share your data that we hold based on the following legal basis:</p>

                        <p>Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.</p>

                        <p> Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</p>

                        <p> Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</p>

                        <p>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal processes, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</p >

                        <p>Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</p>

                        <p>More specifically, we may need to process your data or share your personal information in the following situations:?</p>

                        <p>Business Transfers.We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p >


                    </div>

                    <div className="privacy-details-que">
                        <h3 className='privacy-title'>4.Do we use cookies and other tracking technologies?</h3>

                        <p>We may use cookies and other tracking technologies to collect and store your information.</p>

                        <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
                        </p>
                    </div>


                    <div className="privacy-details-que">
                        <h3 className='privacy-title'>5. How long do we keep your information?</h3>

                        <p>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>

                        <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us to keep your personal information for longer than 2 years.</p>

                    </div>


                    <div className="privacy-details-que">
                        <h3 className='privacy-title'>6. How do we keep your information safe?</h3>

                        <p>We aim to protect your personal information through a system of organizational and technical security measures.</p>

                        <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information.</p>

                        <p>Although we will do our best to protect your personal information, the transmission of personal information to and from our Website is at your own risk. You should only access the Website within a secure environment.</p>
                    </div>

                    <div className="privacy-details-que">
                        <h3 className='privacy-title'>7. Do we collect information from minors?</h3>

                        <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Website, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Website. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at info[.at.]shreesoftic[.dot.]email.</p>

                    </div>


                    <div className="privacy-details-que">
                        <h3 className='privacy-title'>8. What are your privacy rights?</h3>

                        <p>In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</p>

                        <p>In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.</p>
                        <p>If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>

                        <p>If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: National Data Protection Authorities</p>
                    </div>

                    <div className="privacy-details-que">
                        <h3 className='privacy-title'>9. Controls for do-not-track features</h3>

                        <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized.</p>

                        <p>As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>

                    </div>

                    <div className="privacy-details-que">
                        <h3 className='privacy-title'>10. Do we make updates to this notice?</h3>

                        <p>Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>

                        <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                        </p>
                    </div>


                    <div className="privacy-details-que">
                        <h3 className='privacy-title'>11. How can you contact us about this notice?</h3>

                        <p>If you have questions or comments about this notice, you may email us at <a href="mailto: contact@shreesoftic.com" aria-hidden='contact email'>contact@shreesoftic.com</a></p>

                        <p>Shreesoftic </p>
                        <p>4th floor, 20, Shree Nilkanth Business
                            hub</p>
                        <p>Causeway Rd, opp. D-Mart,</p>
                        <p>Katargam, Surat, Gujarat 395004.</p>
                    </div>


                    <div className="privacy-details-que">
                        <h3 className='privacy-title'>3. Will your information be shared with anyone?</h3>

                        <p>We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>

                        <p>We may process or share your data that we hold based on the following legal basis:</p>

                        <p>Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.</p>

                        <p> Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</p>

                        <p> Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</p>

                        <p>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal processes, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</p >

                        <p>Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</p>

                        <p>More specifically, we may need to process your data or share your personal information in the following situations:?</p>

                        <p>Business Transfers.We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p >

                    </div>


                </div>
            </div >
        </>
    )
}

export default Privacy