import React from 'react'
import teamdata from '../../src/Data/teamdata.json';
import { motion } from "framer-motion";
import CountUp from 'react-countup';

const Team = () => {
    return (
        <div className="home-team">
            <div className="home-team-flex">

                <div className="home-team-title">
                    <h6>Why Choose Shreesoftic?</h6>
                    <h1>
                        Empowering Your Success with Premier IT Solutions!
                    </h1>
                    <motion.p initial={{ y: 100, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}>We have a proven track record of delivering high-quality projects on time and within budget. Our team excels in a variety of programming languages and frameworks, including Java, Kotlin, Swift, React/React Native, Flutter, and Angular. With extensive experience in database design, cloud computing, and agile development methodologies, we are equipped to handle any project, from simple websites to complex mobile apps. Partner with us to turn your vision into reality. Contact us today to learn more about how our services can help your business thrive in the digital world.</motion.p>
                </div>

                <div className="home-team-title">
                    {
                        teamdata.map(item => (
                            <div className="team-div" key={item.id}>
                                <div className="team-client">
                                    <span className="number">
                                        <CountUp start={0} end={item.number} duration={4} />
                                    </span>
                                    <span className="plus">+</span>
                                    <p>{item.title}</p>
                                </div>
                                <motion.div
                                    initial={{ y: 100, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 1 }}
                                    viewport={{ once: true }}
                                    className="team-description">
                                    <div className="team-title">
                                        <p>{item.description}</p>
                                    </div>
                                </motion.div>

                            </div>
                        ))
                    }
                </div>
            </div>
        </div>

    )
}

export default Team