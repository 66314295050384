import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdOutlineMail, MdOutlinePhoneIphone } from "react-icons/md";
import { FaRegMap } from "react-icons/fa";
import { BiLogoLinkedin } from "react-icons/bi";
import { IoLogoInstagram } from "react-icons/io";


function Footer() {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location]);

    return (
        <div className='footer'>
            <div className="footer-div">

                <div className="footer-main">

                    <div className="footer-content">
                        <div className='logo'>
                            <img src='/Images/footericon.png' alt='footer-shreesoftic' style={{ width: 190 }} />
                        </div>
                        <h3>About Us</h3>
                        <p>At Shreesoftic, we empower small to mid-sized businesses by providing innovative IT solutions that drive growth and ensure sustainability. We are dedicated to your success and committed to delivering excellence.</p>

                        <div className="footer-icons">
                            <div className="footer-social">
                                <div className="footer-col">
                                    <a href='https://www.linkedin.com/' ><BiLogoLinkedin /></a>
                                </div>
                                <div className="footer-col">
                                    <a href='https://www.instagram.com/' > <IoLogoInstagram /></a>
                                </div>

                                {/* <div className="footer-col">
                                    <CgFacebook />
                                </div> */}
                                {/* <div className="footer-col">
                                    <a href='https://www.twitter.com/'>  <TfiTwitterAlt /></a>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="footer-content" style={{ textAlign: 'center' }}>
                        <h3>Quick Links</h3>
                        <div className="footer-link">
                            <p>
                                <Link
                                    to="/"
                                    className={activeLink === '/' ? 'active' : ''}
                                >
                                    Home
                                </Link>
                            </p>
                            <p>
                                <Link
                                    to="/aboutus"
                                    className={activeLink === '/aboutus' ? 'active' : ''}
                                >
                                    About Us
                                </Link>
                            </p>
                            <p>
                                <Link
                                    to="/services"
                                    className={activeLink === '/services' ? 'active' : ''}
                                >
                                    Services
                                </Link>
                            </p>
                            <p>
                                <Link
                                    to="/career"
                                    className={activeLink === '/career' ? 'active' : ''}
                                >
                                    Career
                                </Link>
                            </p>
                            <p>
                                <Link
                                    to="/contactus"
                                    className={activeLink === '/contactus' ? 'active' : ''}
                                >
                                    Contact Us
                                </Link>
                            </p>
                        </div>
                    </div>

                    <div className="footer-content">
                        <h3>Contact Us</h3>
                        <div className="footer-info">
                            <div className="footer-col">
                                <MdOutlineMail />
                            </div>
                            <div className="footer-email-content">
                                <a href='mailto:contact@shreesoftic.com' aria-hidden='contact email'>contact@shreesoftic.com</a>
                            </div>
                        </div>

                        <div className="footer-info">
                            <div className="footer-col">
                                <MdOutlinePhoneIphone />
                            </div>
                            <div className="footer-email-content">
                                <a href='tel:+919724283087' aria-hidden='tel phonenumber'>+91 97242 83087</a>
                            </div>

                        </div>

                        <div className="footer-info">
                            <div className="footer-col">
                                <FaRegMap />
                            </div>
                            <div className="footer-email-content">
                                <a href='#' aria-hidden='true'>4th floor, 20, Shree Nilkanth Business<br /> hub, Causeway Rd, opp. D-Mart,<br /> Katargam, Surat, Gujarat 395004.</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-privacy">
                    <div className="footer-flex">
                        <div className="footer-copyrigth">
                            <p>&#169; 2024 All Rights Reserved - Shreesoftic.com</p>
                        </div>

                        <div className="footer-term">
                            <div className="footer-privacy-link">
                                <Link className={activeLink === '/privacy' ? 'active' : ''} to="/privacy">Privacy Policy </Link>
                            </div>
                            <div className="footer-term">
                                <p>|</p>
                            </div>
                            <div className="footer-term">
                                <Link className={activeLink === '/condition' ? 'active' : ''} to="/condition"> Terms & Conditions
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer;
