import React from 'react'
import { FcProcess } from "react-icons/fc";
import { PiHandshakeLight } from "react-icons/pi";
import { PiUserSwitchLight } from "react-icons/pi";
// import { RiComputerLine } from "react-icons/ri";
import career from '../Data/career.json';
import { motion } from "framer-motion";
import { MdOutlineCelebration } from "react-icons/md";


const Career = () => {

    return (
        <div className="career-flex">

            <div className="career-label">
                <motion.div
                    initial={{ y: -100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    className="career-uper-section">
                    <h1>Join Our Team <br /> and Shape the Future</h1>
                    <p>At Shreesoftic, we're passionate about innovation and excellence. We're looking for talented individuals to join our dynamic team and contribute to groundbreaking projects. If you're driven by creativity and thrive in a collaborative environment, let's build the future together.</p>
                </motion.div>

                <motion.div
                    animate={{ y: [0, -20, 0], transition: { duration: 3, repeat: Infinity } }}
                    className="career-uper-section">
                    <img src='Images/career2.png' alt='career2img' aria-hidden='true' />
                </motion.div>
            </div>


            <div className="home-service">
                <div className="home-service-header">
                    <motion.h2 initial={{ y: -50, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}>Care of Employees</motion.h2>
                    <motion.div
                        initial={{ y: 60, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        className="home-service-descitption">

                        <p>At Shreesoftic, we believe that our employees are our greatest asset. We are committed to creating a supportive and enriching environment that fosters growth, well-being, and satisfaction. Explore the unique benefits we offer to our team members.</p></motion.div>
                </div>



                <div className="service-grid-div">

                    <div className="services-grid" style={{ marginTop: '50px' }}>

                        <div
                            className="service-card" id='service-change-bg-career' >

                            <motion.div
                                whileHover={{ scale: 1.2 }}
                                whileTap={{ scale: 0.8 }}
                                className="service-icon" id='service-class-change' ><PiUserSwitchLight /></motion.div>
                            <h3 id='service-change' >{career[0].title}</h3>
                            <p id='service-text' >{career[0].description}

                            </p>
                        </div>


                        <div className="service-card">

                            <div className="workflow-card">
                                <motion.div
                                    whileHover={{ scale: 1.2 }}
                                    whileTap={{ scale: 0.8 }}
                                    className="service-icon"><FcProcess /></motion.div>
                                <h3>{career[1].title}</h3>
                                <p>{career[1].description}</p>
                            </div>
                        </div>
                    </div>



                    <div className="services-grid">

                        <div className="service-card">
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.8 }}
                                className="service-icon"><PiHandshakeLight /></motion.div>
                            <h3>{career[2].title}</h3>
                            <p>{career[2].description}</p>
                        </div>


                        <div className="service-card" id='service-change-career1' >

                            <div className="workflow-card">
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.8 }}
                                    className="service-icon" id='text-change' ><MdOutlineCelebration /></motion.div>
                                <h3 id='service-text-change' >{career[3].title}</h3>
                                <p id='p-change' >{career[3].description}</p>
                            </div>
                        </div>


                    </div>

                </div>


            </div>


            <motion.div

                className="career-work"
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}>
                <div className="career-work-section">
                    <img src='Images/fancy.png' alt="Careerfancywork" aria-hidden='true' />
                </div>

                <div className="career-uper-section">
                    <h2>Fancy Working for <br />Shreesoftic?</h2>
                    <p>Join Shreesoftic and take your career to the next level. We’re looking for talented, energetic individuals to be part of our innovative team. At Shreesoftic, you’ll enjoy a range of benefits, including competitive salaries, career advancement opportunities, and a positive work environment. We offer flexible working hours, cutting-edge technology, and a supportive team culture. Experience professional growth through our training programs, work on impactful projects, and collaborate with a global client base. With generous vacation time and a focus on work-life balance, Shreesoftic is the ideal place for ambitious professionals.
                    </p>
                </div>


            </motion.div>


        </div>
    )
}

export default Career


