import React from 'react'
import Team from '../home-component/Team'
import { motion } from "framer-motion";

const Aboutus = () => {
    return (
        <div>
            <div className="about-section">
                <motion.div
                    initial={{ y: -100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    className="contact-header-title">
                    <h1>About Us</h1>
                    <p>At Shreesoftic, we transform ideas into digital masterpieces. Since our inception, we’ve delivered top-notch web and mobile development services. Our expert team leverages the latest technologies to create innovative, reliable solutions tailored to your unique needs. Join us on our journey to achieve digital success and remarkable growth.</p>
                </motion.div>
            </div>
            <Team />
        </div>
    )
}

export default Aboutus