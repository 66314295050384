import React from 'react'
import { motion } from "framer-motion";

const Condition = () => {
    return (
        <>
            <div className='privacy'>
                <div className="privacy-div">
                    <h1>Terms and Conditions</h1>
                    <p>Shreesoftic maintains this Site for information about our product and services. Please feel free to browse the Site.
                    </p>
                </div>
            </div>

            <div className="privacy-section">
                <div className="privacy-flex">
                    <div className="privacy-details">

                        <h2 className='privacy-title'>Information We collect</h2>
                        <p className='privacy-term'>Your access and use of the Site is also subject to the following terms and conditions and future revisions if any, (“Terms and Conditions”) and all applicable laws. By accessing and browsing the Site, you accept the Terms and Conditions below.</p>

                        <ul className='privacy-infomation-ul'>

                            <li>The Site, and all the content, materials, information, software, products and services provided on the Site, are provided on an “as is” and “as available” basis. NISL expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranty of merchantability, fitness for a particular purpose and non-infringement.</li>

                            <li>The Content and the projects described or depicted on the Site are subject to change without notice. Copyright may exist on any future materials. Unauthorized recording of such materials would be contrary to applicable copyright laws.</li>

                            <li>NISL assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect, your computer, equipment or other property on account of your access to, use of or browsing the Site or your downloading of any materials, data, text, images, video, or audio from the Site.</li>

                            <li>Materials displayed on the Site are either the property of, or used with permission by NISL. The use of these materials by you or anyone else authorized by you is prohibited unless expressly permitted by these Terms and Conditions or express written permission is obtained from NISL. Any unauthorized use of the images may violate copyright laws, trademark laws, the laws of privacy and publicity.</li>


                            <li>NISL is not responsible for the content of any other site linked to or from the Site. Your linking to any other site/sites is entirely at your own risk. While NISL may provide links on the Site to other sites, the inclusion of such links is only for your convenience and should not be interpreted as an endorsement of the owner/sponsor of the site or the content on the other site/s. Subject to the Non-Excludable Rights (above), NISL disclaims all warranties, express and implied, as to the accuracy, validity, legality or otherwise of any materials or information contained on such sites.</li>

                            <li>You are prohibited from posting or transmitting any unlawful, threatening, harassing, defamatory, libelous, obscene, pornographic or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense or give rise to civil liability or otherwise violate any law.</li>

                            <li>NISL may at any time revise these Terms and Conditions by updating this post. Since you are bound by these Terms and conditions, please visit this page regularly to learn about the revised Terms and Conditions if any.</li>
                        </ul>

                    </div>
                    <motion.div
                        initial={{ x: 100, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        className="privacy-description">
                        <img src='/Images/condition.png' alt="team & condition" aria-hidden='true' />
                    </motion.div>
                </div>

            </div >
        </>
    )
}

export default Condition